<template>
  <div v-if="loading || editMentor === undefined">
    <div class="box-style py-12">
      <LoadingSpinner />
    </div>
  </div>
  <div v-if="!formComplete">
    <p ref="inlineMessage">
      Please help us update your information by making any changes below:
    </p>
    <div v-if="errorMessage.length" class="mb-5">
      <error-banner title="Form Error" :messages="errorMessage" error />
    </div>
    <div class="box-style mb-5">
      <div class="thirdhead-text">Mentor Details</div>
      <div class="profile_box grid grid-cols-2 gap-5">
        <div>
          <div class="mb-3">
            <div class="mb-2">
              <label for="dropdown-prefix"> Prefix </label>
            </div>
            <select
              v-model="mentor_prefix"
              id="dropdown-prefix"
              class="py-2 px-3 w-full rounded border border-steel-gray"
            >
              <option :value="null"></option>
              <option
                v-for="(prefix, index) in lookup_prefix_options"
                :value="prefix"
                :key="index"
                class="h-8"
              >
                {{ prefix }}
              </option>
              <option value="other">Other</option>
            </select>
          </div>
          <div class="mb-3" v-if="show_other_prefix">
            <div class="mb-2">
              <label for="textbox-otherSuffix">Other Prefix</label>
            </div>
            <input
              id="textbox-otherPrefix"
              type="text"
              class="py-2 px-3 w-full rounded border border-steel-gray"
              v-model.trim="mentor_prefix"
              maxlength="100"
            />
          </div>

          <div class="mb-3">
            <div class="mb-2">
              <label class="" for="textbox-nickname"> Preferred Name </label>
            </div>
            <input
              id="textbox-nickname"
              type="text"
              class="py-2 px-3 w-full rounded border border-steel-gray"
              v-model.trim="nickname"
              maxlength="500"
            />
          </div>

          <div class="mb-3">
            <div class="mb-2">
              <label class="" for="textbox-firstname"> First Name </label>
            </div>
            <input
              id="textbox-firstname"
              type="text"
              class="py-2 px-3 w-full rounded border border-steel-gray"
              v-model.trim="firstName"
              maxlength="500"
            />
          </div>

          <div class="mb-3">
            <div class="mb-2">
              <label for="textbox-lastName"> Last Name </label>
            </div>
            <input
              id="textbox-lastName"
              type="text"
              class="py-2 px-3 w-full rounded border border-steel-gray"
              v-model.trim="lastName"
              maxlength="500"
            />
          </div>

          <div class="mb-3">
            <div class="mb-2">
              <label for="dropdown-suffix"> Suffix </label>
            </div>
            <select
              id="dropdown-suffix"
              class="py-2 px-3 w-full rounded border border-steel-gray"
              v-model="mentor_suffix"
            >
              <option :value="null"></option>
              <option
                v-for="(suffix, key) in lookup_suffix_options"
                :key="`suffix_${key}`"
                :value="suffix"
              >
                {{ suffix }}
              </option>
              <option value="other">Other</option>
            </select>
          </div>
          <div class="mb-3" v-if="show_other_suffix">
            <div class="mb-2">
              <label for="textbox-otherSuffix">Other Suffix</label>
            </div>
            <input
              id="textbox-otherSuffix"
              type="text"
              class="py-2 px-3 w-full rounded border border-steel-gray"
              v-model.trim="mentor_suffix"
              maxlength="100"
            />
          </div>
          <div class="hidden">Mentor Since: {{ formatDate }}</div>
        </div>
        <div>
          <p class="mb-2">The questions below are optional to allow you to provide any additional information you would like us to consider in pairing you with your student.</p>
          <div class="mb-2">Ethnicity</div>
          <div class="pb-3">
            <div
              v-for="(value, idx) in lookup_ethnicity"
              :key="`ethnicity_${idx}`"
            >
              <label>
                <input
                  :id="`ethnicity_${idx}`"
                  :data-cy="`ethnicity_${idx}`"
                  name="ethnicity-check"
                  :aria-label="'checkbox for race that applies ' + value.races"
                  type="checkbox"
                  class="my-1 mr-1 rounded"
                  :value="value"
                  v-model="mentor_ethnicity"
                />
                {{ value.race }}
              </label>
            </div>
            <label for="textbox-addotherethnicity">
              <input
                id="textbox-addotherethnicity"
                name="ethnicity-check"
                type="checkbox"
                class="my-1 mr-1 rounded"
                v-model="included_other_ethnicity"
                data-cy="edit-student-other-ethnicity"
              />
              Add another ethnicity
            </label>
            <div v-if="included_other_ethnicity" class="pt-2">
              <input
                type="text"
                aria-label="other ethnicity"
                class="border-steel-gray rounded h-8 w-1/2"
                v-model="ethnicity_custom.race"
                placeholder="Add another ethnicity"
                data-cy="edit-student-other-ethnicity-textfield"
              />
            </div>
          </div>

          <!-- gender -->
          <div class="mb-2">Gender</div>
          <div class="pb-3">
            <ul>
              <li
                v-for="(gender_option, idx) in lookup_gender_options"
                :key="`gender_${idx}`"
              >
                <label :for="`gender_${idx}`">
                  <input
                    :id="`gender_${idx}`"
                    :data-cy="`gender_${idx}`"
                    name="gender_option"
                    type="radio"
                    class="my-1 mr-1"
                    :value="gender_option"
                    v-model="mentor_gender"
                  />
                  {{ gender_option }}
                </label>
              </li>
            </ul>
            <div
              v-if="
                mentor_gender !== null &&
                (mentor_gender === 'Add another gender' ||
                  !lookup_gender_options.includes(mentor_gender))
              "
              class="pt-2"
            >
              <input
                type="text"
                aria-label="add another gender"
                class="border-steel-gray rounded h-8 w-1/2"
                v-model="mentor_gender"
                placeholder="Add another gender"
                data-cy="edit-student-other-gener-textfield"
              />
            </div>
          </div>

          <!-- pronouns -->

          <div class="mb-2">Pronouns</div>
          <div class="pb-3">
            <ul>
              <li
                v-for="(pronouns_option, idx) in lookup_pronouns_options"
                :key="`pronouns_${idx}`"
              >
                <label :for="`pronouns_${idx}`">
                  <input
                    :id="`pronouns_${idx}`"
                    :data-cy="`pronouns_${idx}`"
                    name="pronoun_option"
                    type="radio"
                    class="my-1 mr-1"
                    :value="pronouns_option"
                    v-model="mentor_pronouns"
                  />
                  {{ pronouns_option }}
                </label>
              </li>
            </ul>
            <div
              v-if="
                mentor_pronouns !== null &&
                (mentor_pronouns === 'Add another pronoun set' ||
                  !lookup_pronouns_options.includes(mentor_pronouns))
              "
              class="pt-2"
            >
              <input
                type="text"
                aria-label="add another pronoun"
                class="border-steel-gray rounded h-8 w-1/2"
                v-model="mentor_pronouns"
                placeholder="Add another pronoun set"
                data-cy="edit-student-other-pronouns-textfield"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box-style mb-5 overflow-auto">
      <div class="thirdhead-text">Mentor Contact Info</div>
      <div class="profile_box min-w-max">
        <p class="pb-3">Information marked visible can be seen by your student.</p>
        <div class="grid grid-cols-1 gap-1">
          <div class="col-span-1">
            <div class="mb-3">
              <div class="mb-2">Email</div>
              <div v-for="(email, index) in this.emails" :key="email?.email_id">
                <div
                  class="grid grid-cols-8 gap-1 items-center mb-1"
                  v-if="
                    emailControl[index] === undefined ||
                    emailControl[index] === false
                  "
                >
                  <font-awesome-icon
                    class="text-dark-purple"
                    icon="envelope"
                    aria-label="envelope icon to identify email section"
                  />
                  <div>{{ email?.email_type }}</div>
                  <div class="col-span-2">{{ email?.email_address }}</div>
                  <div>
                    {{ email?.is_primary === true ? "Primary" : "" }}
                  </div>
                  <div>
                    {{ email?.is_public === true ? "Visible" : "" }}
                  </div>
                  <font-awesome-icon
                    @click="emailControl[index] = true"
                    class="text-purple col-start-7 hover:cursor-pointer"
                    icon="edit"
                    aria-label="edit email"
                  />
                  <font-awesome-icon
                    @click="this.emails.splice(index, 1)"
                    class="text-error-text hover:cursor-pointer"
                    icon="trash"
                    aria-label="delete"
                  />
                </div>
                <AddEditEmail
                  v-else
                  :editEmails="email"
                  @cancelChange="emailControl[index] = false"
                  @emailChanged="emailUpdated($event, index)"
                />
              </div>
              <button
                v-if="!this.addEmail"
                aria-label="Add Email"
                @click="this.addEmail = true"
                class="hover:cursor-pointer text-purple"
              >
                <font-awesome-icon icon="plus" />
              </button>
              <AddEditEmail
                v-else
                @cancelChange="this.addEmail = false"
                @emailChanged="addEmailAddress($event)"
              />
            </div>
            <div class="mb-3">
              <div class="mb-2">Phone</div>
              <div
                v-for="(phone, index) in this.phoneNumbers"
                :key="phone?.phone_id"
              >
                <div
                  class="grid grid-cols-8 gap-1 items-center"
                  v-if="
                    phoneControl[index] === undefined ||
                    phoneControl[index] === false
                  "
                >
                  <font-awesome-icon
                    class="text-dark-purple"
                    icon="phone"
                    aria-label="phone icon to identify phone section"
                  />
                  <div>
                    {{ phone?.phone_type }}
                  </div>
                  <div class="col-span-2">{{ phone?.phone_number }}</div>
                  <div>{{ phone?.is_primary === true ? "Primary" : "" }}</div>
                  <div>{{ phone?.is_public === true ? "Visible" : "" }}</div>

                  <font-awesome-icon
                    class="text-purple col-start-7 hover:cursor-pointer"
                    icon="edit"
                    aria-label="edit phone number"
                    @click="phoneControl[index] = true"
                  />
                  <font-awesome-icon
                    class="text-error-text hover:cursor-pointer"
                    icon="trash"
                    aria-label="delete phone number"
                    @click="this.phoneNumbers.splice(index, 1)"
                  />
                </div>
                <div v-else>
                  <AddEditPhone
                    :editPhone="phone"
                    @cancelChange="phoneControl[index] = false"
                    @phoneNumberChanged="phoneNumberEdited($event, index)"
                  />
                </div>
              </div>
              <div v-if="this.addPhone">
                <AddEditPhone
                  @cancelChange="this.addPhone = false"
                  @phoneNumberChanged="addPhoneNumber($event)"
                />
              </div>
              <div v-if="!this.addPhone">
                <button
                  class="hover:cursor-pointer text-purple"
                  aria-label="Add Phone"
                  @click="
                    this.addPhone
                      ? (this.addPhone = false)
                      : (this.addPhone = true)
                  "
                >
                  <font-awesome-icon icon="plus" />
                </button>
              </div>
            </div>
            <div class="mb-2">Address</div>
            <div
              v-for="(address, index) in this.addresses"
              :key="address?.address_id"
              class="grid grid-cols-8 gap-1"
            >
              <template
                v-if="
                  addressControl[index] === undefined ||
                  addressControl[index] === false
                "
              >
                <font-awesome-icon
                  class="text-dark-purple"
                  icon="building"
                  aria-label="building icon to identify address section"
                />
                <div class="col-span-1">{{ address.address_type }}</div>
                <div class="col-span-2">
                  {{ address.address_line_one }}
                  {{ address.address_line_two }}
                  {{ address.city }}
                  {{ address.state }},
                  {{ address.zip_code }}
                </div>
                <div class="col-span-1">
                  {{ address.is_primary === true ? "Primary" : " " }}
                </div>
                <div class="col-span-1">
                  {{ address.is_public === true ? "Visible" : " " }}
                </div>
                <font-awesome-icon
                  class="text-purple col-start-7 hover:cursor-pointer"
                  icon="edit"
                  aria-label="edit phone number"
                  @click="addressControl[index] = true"
                />
                <font-awesome-icon
                  class="text-error-text hover:cursor-pointer"
                  icon="trash"
                  aria-label="delete address"
                  @click="this.addresses.splice(index, 1)"
                />
              </template>
              <AddEditAddress
                class="col-span-full"
                v-else
                :editAddress="address"
                @cancelChange="addressControl[index] = false"
                @addressChanged="addressUpdated($event, index)"
              />
            </div>
            <button
              v-if="this.addAddress === false"
              class="hover:cursor-pointer text-purple"
              aria-label="Add Address"
              @click="this.addAddress = true"
            >
              <font-awesome-icon icon="plus" />
            </button>
            <AddEditAddress
              v-else
              @cancelChange="this.addAddress = false"
              @addressChanged="addPhysicalAddress($event)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="box-style mb-5 overflow-auto">
      <div class="thirdhead-text">Employer</div>
      <div class="profile_box min-w-max">
        <div class="flex mb-5">
          <div>
            <div class="font-semibold text-dark-purple mb-1">
              {{ current_employer.employer_name }}
            </div>
          </div>
        </div>
        <div>
          <div>
            <label for="textbox-position"> Position </label>
            <input
              id="textbox-position"
              type="text"
              class="w-100 rounded border border-steel-gray"
              v-model.trim="employer_position"
              maxlength="500"
            />
          </div>
        </div>
        <div>
          <div>
            <label for="checkbox-employerChanged"
              >Has your employer changed?
            </label>
            <input
              v-model="employerChanged"
              type="checkbox"
              class="ml-3 rounded border border-steel-gray"
              id="checkbox-employerChanged"
            />
          </div>
          <div v-if="employerChanged">
            <label for="textbox-newEmployer"
              >What is your new employer name?</label
            >
            <input
              v-model="newEmployerName"
              type="text"
              class="ml-3 rounded border border-steel-gray"
              id="textbox-newEmployer"
            />
          </div>
        </div>
      </div>
      <div class="my-5">
        <div class="overflow-auto">
          <label class="thirdhead-text">Practice Areas</label>
          <div>
            <draggable-list
              v-model="profile_practiceAreas"
              @remove="removePracticeArea"
              list_of="practice_areas"
              identifier="practice_area_id"
              data-cy="interests-list"
            />
            <div class="flex justify-start my-2">
              <div>
                <Button
                  :primary="false"
                  text="Add Practice Area"
                  @click="show_practice_area_modal = !show_practice_area_modal"
                />
              </div>
              <ListLookupModal
                v-if="show_practice_area_modal"
                class="w-1/2 left-1/4"
                :items="filtered_lookup_practice_areas"
                @addSelection="pushPracticeAreaSelection"
                @closeModal="
                  show_practice_area_modal = !show_practice_area_modal
                "
                data-cy="list-lookup-modal-interests"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="profile_box">
        <div>
          <label class="thirdhead-text py-2" for="dropDown-continueMentoring"
            >Would you like to continue as a mentor for the next academic year?
          </label>
        </div>
        <div>
          <select
            class="rounded border border-steel-gray"
            id="dropDown-continueMentoring"
            v-model="continueMentoring"
          >
            <option :value="true">Yes</option>
            <option :value="false">No</option>
          </select>
        </div>
        <!-- <div v-if="continueMentoring">
          <div class="mb-2">
            <label for="dropdown-preferred-academic-level"
              >Preferred Academic Level</label
            >
          </div>
          <div class="mb-3">
            <select
              class="py-2 px-3 w-full rounded border border-steel-gray"
              v-model="profile_preferedAcademicLevel"
              id="dropdown-preferred-academic-level"
            >
              <option :value="null"></option>
              <option
                v-for="academicLevel in lookup_acad_levels"
                :key="academicLevel.acad_level_id"
                :value="academicLevel.acad_level_id"
              >
                {{ academicLevel.level }}
              </option>
            </select>
          </div>
        </div> -->
        <div v-if="!continueMentoring">
          <div>
            <label class="thirdhead-text py-2" for="dropDown-mentorbreak"
              >Would you like us to contact you next year about being a mentor?
            </label>
          </div>
          <div>
            <select
              class="rounded border border-steel-gray"
              id="dropDown-mentorbreak"
              v-model="mentorNextYear"
            >
              <option :value="true">Yes</option>
              <option :value="false">No</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="text-right pt-3">
      <Button text="Continue" @click="submitForm" />
    </div>
  </div>
</template>

<script>
import Button from "@/components/reusable-components/base-level-components/Button";
import AddEditPhone from "@/views/admin/mentors/mentor-edits/AddEditPhone";
import AddEditEmail from "@/views/admin/mentors/mentor-edits/AddEditEmail";
import AddEditAddress from "@/views/admin/mentors/mentor-edits/AddEditAddress";
import { FormatDate } from "@/composables/date_utils";
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import { get, put, post } from "@/composables/httpUtil";
import { mapActions, mapGetters } from "vuex";

import DraggableList from "@/components/draggable/DraggableList";
import ListLookupModal from "@/components/lookup/ListLookupModal";
import ErrorBanner from "@/components/forms/ErrorBanner";

export default {
  name: "MentorContactSurvey",
  components: {
    ErrorBanner,
    Button,
    LoadingSpinner,
    AddEditPhone,
    AddEditEmail,
    AddEditAddress,
    DraggableList,
    ListLookupModal,
  },
  props: {
    mentorId: {
      type: String,
      required: true,
    },
  },
  created() {
    this.loading = true;
    Promise.all([this.setup_mentor(), this.fetchLookupResource()])
      .catch((error) => {
        console.error(error);
      })
      .finally(() => (this.loading = false));
  },
  data() {
    return {
      continueMentoring: true,
      employerChanged: false,
      newEmployerName: "",

      // formComplete used because the draggable list needs to be rendered on the screen before the api finishs to load it or it wont work right
      // The reason formComplete is here is so when they submitted the form i can show the loading and close the form
      formComplete: false,

      surveyDetails: null,

      id: null,
      mentor_prefix: null,
      nickname: null,
      firstName: null,
      lastName: null,
      mentor_suffix: null,
      emails: [],
      phoneNumbers: [],
      addresses: [],
      mentor_gender: null,
      mentor_pronouns: null,
      mentorSince: null,

      mentorNextYear: true,

      mentor_ethnicity: [],
      included_other_ethnicity: false,
      ethnicity_custom: { race: null, description: "", display: false },

      //employment information
      employer_position: null,
      current_mentor_employer_id: null,
      current_mentor_employer_start_date: null,
      current_mentor_employer_end_date: null,
      current_employer: {
        employer_name: null,
        web_site: null,
        number_employees: null,
        display_employer: true,
        address: {
          address_id: null,
          address_type: "WORK",
          address_line_one: null,
          address_line_two: null,
          city: null,
          state: null,
          zip_code: null,
          is_primary: true,
          is_public: true,
        },
        phone: {
          phone_type: "WORK",
          phone_number: null,
          is_public: true,
          is_primary: true,
        },
      },

      //Mentor Profile
      profile_id: null,
      profile_practiceAreas: [],
      profile_mentorStatus: null,
      profile_alumnusYear: null,
      profile_christmasCards: false,
      profile_preferedAcademicLevel: null,
      profile_isAlumni: false,

      loading: false,
      addView: true,
      editMentor: undefined,

      addPhone: false,
      addEmail: false,
      addAddress: false,

      //edit controls
      phoneControl: {},
      emailControl: {},
      addressControl: {},

      errorMessage: [],

      show_other_prefix: false,
      show_other_suffix: false,
      show_employer_modal: false,
      show_practice_area_modal: false,
    };
  },
  watch: {
    mentor_suffix(new_suffix) {
      this.show_other_suffix =
        (new_suffix === "Other" ||
          !this.lookup_suffix_options.includes(new_suffix)) &&
        new_suffix;
    },
    mentor_prefix(new_prefix) {
      this.show_other_prefix =
        (new_prefix === "Other" ||
          !this.lookup_prefix_options.includes(new_prefix)) &&
        new_prefix;
    },
  },
  methods: {
    FormatDate,
    get,
    put,
    post,
    ...mapActions(["fetchLookupResource"]),
    setup_mentor() {
      return new Promise((resolve, reject) => {
        this.id = this.mentorId;
        this.get("mentor/" + this.id)
          .then((results) => {
            this.surveyDetails = results;
            this.editMentor = results.mentor;

            this.mentor_prefix = this.editMentor.prefix;
            this.nickname = this.editMentor.nickname;
            this.firstName = this.editMentor.firstname;
            this.lastName = this.editMentor.lastname;
            this.mentor_suffix = this.editMentor.suffix;

            this.mentor_gender = this.editMentor.gender;

            this.mentor_pronouns = this.editMentor.pronouns;

            this.editMentor.ethnicity.forEach((eth) => {
              if (
                this.lookup_ethnicity.findIndex(
                  (e) => e.ethnicity_id === eth.ethnicity_id
                ) >= 0
              ) {
                this.mentor_ethnicity.push(eth);
              } else {
                this.included_other_ethnicity = true;
                this.ethnicity_custom = eth;
              }
            });

            this.emails = this.editMentor.email;

            this.phoneNumbers = this.editMentor.phone;

            this.addresses = this.editMentor.address;

            this.mentorSince = this.editMentor.added;

            // current employer
            if (this.editMentor.employer.length > 0) {
              const current_employer = this.editMentor.employer.find(
                (emp) => emp.current
              );
              this.employer_position = current_employer?.job_title;
              this.current_mentor_employer_id =
                current_employer?.mentor_employer_id;
              this.current_mentor_employer_start_date =
                current_employer?.from_date;
              this.current_mentor_employer_end_date = current_employer.to_date;

              this.current_employer = current_employer?.employer;
            }
            //mentor profile
            const mentorProfile = this.editMentor.profile;
            this.profile_id = mentorProfile.mentor_profile_id;
            this.profile_practiceAreas = [...mentorProfile.practice_areas].sort(
              (a, b) => a.priority - b.priority
            );
            this.profile_alumnusYear = mentorProfile.alumnus_year;
            if (this.profile_alumnusYear > 0) {
              //if alumni year is set then they are an alumni so setting the boolean value as such
              this.profile_isAlumni = true;
            }
            this.profile_christmasCards = mentorProfile.religious_greeting;
            this.profile_preferedAcademicLevel =
              mentorProfile.preferred_acad_level;

            //pairings
            this.student_pairings = results.student_pairings;
          })
          .then(() => {
            resolve();
          })
          .catch((err) => {
            this.loading = false;
            reject(Error(err));
          });

        resolve();
      });
    },
    submitForm() {
      if (this.validateForm()) {
        const mentor = this.formatMentor();
        this.loading = true;
        this.formComplete = true;
        this.put("mentor/" + this.id, mentor)
          .then((response) => {
            this.surveyDetails.mentor = response;

            if(this.newEmployerName !== "") {
              this.sendNote();
            }

            this.$emit("informationUpdated", this.surveyDetails);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    sendNote() {
      const body = {
        text: 'New Employer: ' +this.newEmployerName,
        author_id: this.id,
        person_id: this.id,
      };
      this.post(`mentor/${this.id}/note`, body);
    },
    validateForm() {
      this.errorMessage = [];
      if (!this.addresses.length) {
        this.errorMessage.push(
          "A mentor is required to have at least one address"
        );
      }
      if (!this.profile_practiceAreas.length) {
        this.errorMessage.push(
          "A mentor is required to have at least one practice area"
        );
      }
      if (!this.phoneNumbers.length) {
        this.errorMessage.push(
          "A mentor is required to have at least one phone number"
        );
      }
      if (!this.emails.length) {
        this.errorMessage.push(
          "A mentor is required to have at least one email"
        );
      }
      if (!this.firstName) {
        this.errorMessage.push("Please add a first name");
      }
      if (!this.lastName) {
        this.errorMessage.push("Please add a last name");
      }
      if (this.errorMessage.length) {
        this.$refs["inlineMessage"].scrollIntoView({ behavior: "smooth" });
        return false;
      }
      return true;
    },
    formatMentor() {
      let mentor = this.editMentor;

      mentor.prefix = this.mentor_prefix;
      mentor.firstname = this.firstName;
      mentor.lastname = this.lastName;
      mentor.nickname = this.nickname;
      mentor.suffix = this.mentor_suffix;
      mentor.mentor_status = this.setMentorStatus();
      mentor.gender = this.mentor_gender;
      mentor.pronouns = this.mentor_pronouns;
      mentor.ethnicity = this.mentor_ethnicity;
      mentor.address = this.formatAddresses();
      mentor.phone = this.formatPhones();
      mentor.email = this.formatEmails();
      mentor.profile = this.formatProfile();
      mentor.employer = this.formatEmployers();
      delete mentor.student_pairings;

      // mentor.student_pairings.forEach((element) => {
        //removing student pairings student_at_level because we do not wish to update them with this call and there is backend protection against it, it does not remove anything in the database
        // delete element.student_at_level;
      // });

      if (this.included_other_ethnicity) {
        mentor.ethnicity.push(this.ethnicity_custom);
      }

      return mentor;
    },
    setMentorStatus() {
      if (this.continueMentoring) {
        return "ACTIVE";
      } else if (this.mentorNextYear) {
        return "CALL_IN_FUTURE";
      } else {
        return "RETIRED";
      }
    },
    formatAddresses() {
      return this.addresses.map((address) => {
        return {
          address_id: address.address_id,
          address_type: address.address_type,
          address_line_one: address.address_line_one,
          address_line_two: address.address_line_two,
          city: address.city,
          state: address.state,
          zip_code: address.zip_code,
          from_date: address.from_date,
          to_date: address.to_date,
          updated: address.updated,
          added: address.added,
          deleted: address.deleted,
          is_primary: address.is_primary,
          is_public: address.is_public,
        };
      });
    },
    formatEmails() {
      return this.emails.map((email) => {
        return {
          email_id: email.email_id,
          email_address: email.email_address,
          email_type_id: email.email_type_id,
          person_id: email.person_id,
          email_type: email.email_type,
          added: email.added,
          updated: email.updated,
          deleted: email.deleted,
          is_public: email.is_public,
          is_primary: email.is_primary,
        };
      });
    },
    formatPhones() {
      return this.phoneNumbers.map((phone) => {
        return {
          phone_id: phone.phone_id,
          phone_type: phone.phone_type,
          phone_number: phone.phone_number,
          added: phone.added,
          updated: phone.updated,
          deleted: phone.deleted,
          is_public: phone.is_public,
          is_primary: phone.is_primary,
        };
      });
    },
    formatProfile() {
      return {
        mentor_profile_id: this.profile_id,
        mentor_id: this.id,
        practice_areas: this.profile_practiceAreas,
        preferred_acad_level: this.profile_preferedAcademicLevel,
        recruited_by_admin_id: null,
        updated_by_admin_id: null,
        religious_greeting: this.profile_christmasCards,
        alumnus_year: this.profile_alumnusYear,
      };
    },
    formatEmployers() {
      return [
        {
          mentor_employer_id: this.current_mentor_employer_id,
          mentor_id: this.id,
          employer_id: this.current_employer.employer_id,
          job_title: this.employer_position,
          from_date: this.current_mentor_employer_start_date,
          to_date: this.current_mentor_employer_end_date,
          current: true,
          employer: this.current_employer,
        },
      ];
    },
    updateEmployerData(employer) {
      this.current_employer = employer;
      this.show_employer_modal = !this.show_employer_modal;
    },
    addPhoneNumber(phone) {
      if (phone.is_primary) {
        this.phoneNumbers.forEach((phone) => {
          phone.is_primary = false;
        });
      }

      this.addPhone = false;
      this.phoneNumbers.push(phone);
    },
    phoneNumberEdited(phone, index) {
      if (phone.is_primary) {
        this.phoneNumbers.forEach((phone) => {
          phone.is_primary = false;
        });
      }

      this.phoneControl[index] = false;
      this.phoneNumbers[index] = phone;
    },
    addEmailAddress(email) {
      if (email.is_primary) {
        this.emails.forEach((email) => {
          email.is_primary = false;
        });
      }

      this.addEmail = false;
      this.emails.push(email);
    },
    emailUpdated(email, index) {
      if (email.is_primary) {
        this.emails.forEach((email) => {
          email.is_primary = false;
        });
      }

      this.emailControl[index] = false;
      this.emails[index] = email;
    },
    addPhysicalAddress(address) {
      if (address.is_primary) {
        this.addresses.forEach((address) => {
          address.is_primary = false;
        });
      }

      this.addAddress = false;
      this.addresses.push(address);
    },
    addressUpdated(address, index) {
      if (address.is_primary) {
        this.addresses.forEach((address) => {
          address.is_primary = false;
        });
      }

      this.addressControl[index] = false;
      this.addresses[index] = address;
    },
    pushPracticeAreaSelection: function (selection) {
      this.profile_practiceAreas = [
        ...this.profile_practiceAreas,
        ...selection,
      ];
      this.show_practice_area_modal = !this.show_practice_area_modal;
    },
    removePracticeArea: function (index) {
      this.profile_practiceAreas.splice(index, 1);
    },
  },
  computed: {
    ...mapGetters([
      "lookup_practice_areas",
      "lookup_acad_levels",
      "lookup_suffix_options",
      "lookup_mentor_status_options",
      "lookup_ethnicity",
      "lookup_pronouns_options",
      "lookup_gender_options",
      "lookup_prefix_options",
    ]),
    fullName() {
      let name = `${this.firstName}
            ${this.nickname ? "(" + this.nickname + ")" : ""} ${this.lastName}`;

      if (name) {
        name = `${this.mentor_prefix ? this.mentor_prefix : ""} ${name} ${
          this.mentor_suffix ? this.mentor_suffix : ""
        }`;
      }
      return name;
    },
    formatDate() {
      return FormatDate(this.mentorSince);
    },
    filtered_lookup_practice_areas() {
      return this.lookup_practice_areas
        .filter(
          (pa) =>
            !this.profile_practiceAreas
              .map((i) => i.practice_area_id)
              .includes(pa.practice_area_id)
        )
        .sort((a, b) => {
          return a.description.localeCompare(b.description);
        });
    },
  },
};
</script>

<style scoped></style>
