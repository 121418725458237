<template>
  <div>
    <SurveyHeader />
    <div v-if="mentorId !== ''" class="text-center p-5">
      <div class="text-left">
        <h1 class="header-text pb-2">Survey</h1>

        <MentorContactUpdate
          v-if="!profileUpdated"
          :mentorId="mentorId"
          @informationUpdated="mentorProfileUpdated($event)"
        />

        <MentorQuestions v-else :surveyInformation="surveyInformation" />
      </div>
    </div>
    <div v-else>
      <error-banner
        class="mt-3"
        title="Survey"
        :messages="['There was an issue opening this survey.']"
        info
      />
    </div>
  </div>
</template>

<script>
import SurveyHeader from "./survey-components/SurveyHeader";
import MentorQuestions from "./survey-components/MentorQuestions";
import MentorContactUpdate from "./survey-components/MentorContactUpdate";
import { getUserName } from "@/composables/tokenUtils";
import ErrorBanner from "@/components/forms/ErrorBanner";

export default {
  name: "MentorSurvey",
  created() {
    //set mentor Id from JWT
    let tokenInformation;
    tokenInformation = this.getUserName();

    if (tokenInformation != undefined) {
      this.mentorId = tokenInformation.person_id;
    }
  },
  components: {
    SurveyHeader,
    MentorQuestions,
    MentorContactUpdate,
    ErrorBanner,
  },
  data() {
    return {
      profileUpdated: false,
      surveyInformation: null,
      mentorId: "",
    };
  },
  methods: {
    getUserName,
    mentorProfileUpdated($event) {
      this.profileUpdated = true;
      this.surveyInformation = $event;
    },
  },
};
</script>

<style scoped></style>
