<template>
  <div v-if="!loading">
    <div
      class="card"
      v-if="surveyInformation && !surveyComplete"
    >
      <error-banner
        class="mb-2"
        v-if="showSuccess"
        title="Success"
        :messages="[
          'Thank you for submitting the survey for ' + this.successMessageName,
        ]"
        success
      />
      <form class="box-style text-dark-gray" v-if="activeStudent">
        <h2 class="secondhead-text">
          {{ activeStudent?.name }}
        </h2>
        <div v-if="this.surveyInformation.mentor.mentor_status === 'ACTIVE'">
          <div>
            <label
              class="subhead-text text-dark-gray"
              for="dropDown-continueMentoringStudent"
            >
              {{ this.surveyQuestions?.fields[0]?.name }}
            </label>
          </div>
          <div class="pb-3">
            <select
              v-model="mentorStudentAgain"
              id="dropDown-continueMentoringStudent"
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
        </div>

        <div>
          <label class="subhead-text text-dark-gray" for="textarea-commentsForStudent"
            >{{ this.surveyQuestions?.fields[1]?.name }}
          </label>
        </div>
        <div class="pb-3">
          <textarea
            v-model="studentComments"
            class="text-sm p-1 pl-2 border-steel-gray rounded h-24 w-full"
            id="textarea-commentsForStudent"
          />
        </div>
        <div>
          <label
            class="subhead-text text-dark-gray"
            for="textarea-mentorImprovementComments"
            >{{ this.surveyQuestions?.fields[2]?.name }}</label
          >
        </div>
        <div>
          <textarea
            v-model="programImprovements"
            class="text-sm p-1 pl-2 border-steel-gray rounded h-24 w-full"
            id="textarea-mentorImprovementComments"
          />
        </div>
      </form>
    </div>
    <div class="card" v-else-if="this.studentsToSurvey.length === 0">
        <error-banner
        title="Survey Completed"
        :messages="[
          'Thank you completing this survey',
        ]"
        success
      />
    </div>
    <div class="text-right pt-3" v-if="!surveyComplete">
      <Button
        v-if="studentsToSurvey.length >= 1"
        text="Next"
        @click="nextStudent"
      />
      <Button v-else text="Complete" @click="nextStudent" />
    </div>
  </div>
  <div v-else>
    <div class="box-style">
      <LoadingSpinner />
    </div>
  </div>
</template>

<script>
import Button from "@/components/reusable-components/base-level-components/Button";
import { get, post, put } from "@/composables/httpUtil";
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import ErrorBanner from "@/components/forms/ErrorBanner";

export default {
  name: "MentorQuestions",
  created() {
    //map students to be surved with student names contained in pairings
    if (this.surveyInformation.link_info.students.length > 0) {
      this.getSurveyQuestions();
      const termId = this.surveyInformation.link_info.term_id; 
      this.surveyInformation.mentor.student_pairings.forEach((student) => {
        if (student.student_at_level.term.term_id === termId) {
          const surveyItem = {
            name:
              student.student_at_level.student.firstname +
              " " +
              student.student_at_level.student.lastname,
            studentId: student.student_at_level.student.person_id,
          };
          this.studentsToSurvey.push(surveyItem);
        }
      });

      this.setActiveStudent();
    } else {
      //no students to survey
      this.studentsLeftToSurvey = false;
      this.setActiveStudent();
    }
  },
  data() {
    return {
      activeStudent: null,
      studentsLeftToSurvey: true,
      studentsToSurvey: [],
      surveyQuestions: null,
      mentorStudentAgain: "yes",
      studentComments: "",
      programImprovements: "",

      mentorStudentAgainId: "",
      studentCommentsId: "",
      programImprovementsId: "",

      showSuccess: false,
      successMessageName: "",

      loading: false,

      surveyComplete: false,
    };
  },
  props: {
    surveyInformation: null,
  },
  methods: {
    get,
    post,
    put,
    nextStudent() {
      const body = {
        survey_id: this.surveyInformation.link_info.survey_id,
        mentor_id: this.surveyInformation.mentor.mentor_id,
        student_id: this.activeStudent.studentId,
        term_id: this.surveyInformation.link_info.term_id,
        person_id: this.surveyInformation.mentor.mentor_id,
        status: "COMPLETE",
        fields: [
          //if mentor selected that they did not want to be a mentor again this year then we default the option to no
          {
            field_id: this.mentorStudentAgainId,
            value:
              this.surveyInformation.mentor.mentor_status === "ACTIVE"
                ? this.mentorStudentAgain
                : "no",
          },
          {
            field_id: this.studentCommentsId,
            value: this.studentComments,
          },
          {
            field_id: this.programImprovementsId,
            value: this.programImprovements,
          },
        ],
      };

      this.loading = true;

      this.post(
        `surveys/start/${this.surveyInformation.link_info.survey_id}`,
        body
      )
        .then(() => {
          this.successMessageName = this.activeStudent.name;
          this.showSuccess = true;
          this.loading = false;

          this.resetForm();
          this.setActiveStudent();
        })
        .catch(() => {
          this.successMessageName = "";
          this.showSuccess = false;
          this.loading = false;
        });
    },
    getSurveyQuestions() {
      this.loading = true;
      this.get(
        `surveys/start/${this.surveyInformation.link_info.survey_id}`
      ).then((response) => {
        this.loading = false;

        this.surveyQuestions = response;

        this.mentorStudentAgainId = this.surveyQuestions.fields[0].field_id;
        this.studentCommentsId = this.surveyQuestions.fields[1].field_id;
        this.programImprovementsId = this.surveyQuestions.fields[2].field_id;
      });
    },
    resetForm() {
      this.mentorStudentAgain = "yes";
      this.studentComments = "";
      this.programImprovements = "";
    },
    setActiveStudent() {
      if (this.studentsToSurvey.length > 0) {
        this.activeStudent = this.studentsToSurvey.pop();
      } else {
        //survey finished
        this.loading = true;
        this.activeStudent = null;
        this.surveyComplete = true; 
        this.put(
          `surveys/info/${this.surveyInformation.link_info.link_id}`
        )
          .then(() => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
  components: {
    Button,
    LoadingSpinner,
    ErrorBanner,
  },
};
</script>

<style scoped>

input,
select {
  @apply border-steel-gray rounded h-8 py-0;
}
</style>
