<template>
<div>
        <header class="text-white p-3 bg-purple">
            <div>
                <label><small>UNIVERSITY OF</small></label>
            </div>
            <div>
                <large class="text-3xl"> St. Thomas </large>
            </div>
        </header>
</div>
</template>

<script>
export default {
  name: "SurveyHeader",
};
</script>

<style scoped></style>
